<template>
  <div class="row m-b-lg m-t-lg">
    <div class="col-md-6">
      <div v-if="logoURL" class="profile-image">
          <img :src="logoURL" class="rounded-circle circle-border" alt="logo">
      </div>
      <div :class="(logoURL ? 'profile-info' : '')">
        <h2 class="no-margins">{{ name }}</h2>
        <p>
          
        </p>
      </div>
    </div>
    <div class="col-md-6">
        <ModuleSwitch v-if="modules.length > 1" class="mb-3"></ModuleSwitch>
    </div>
  </div>
</template>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import ModuleSwitch from './ModuleSwitch.vue';

import { useModuleAdmin } from '../../composables/useModuleAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      ModuleSwitch
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedModule, modules } = useModuleAdmin(props, context);

      const logoURL = null;

      const name = computed(() => {
        if(selectedModule.value && selectedModule.value.module) { return selectedModule.value.module.name; }
        return "";
      })

      const id = computed(() => {
        if(selectedModule.value) { return selectedModule.value.module._id; }
        return "-";
      })

      return { 
        modules,
        selectedModule,
        logoURL,
        name,
        id
      }
    }
})
</script>