<template>
  <div class="ibox modules">
    <div class="ibox-title">
      <h2>{{ getLocalizedText(labels.title) }}</h2>
    </div>
    <div :class="'ibox-content p-md'">

      <form role="form" @submit="onFormSubmit" name="update-client-settings">
        <JSONTextArea
          v-bind:value.sync="form.fileTypeConfigs" 
          :label="getLocalizedText(labels.fileTypeConfigs)" 
          :placeholder="getLocalizedText(labels.fileTypeConfigs_placeholder)" 
          :labelAsColumn="true"
        />

        <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.updateButton) }}</button>
      </form>
    </div>
  </div>
</template>

<style>
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import JSONTextArea from '@root/src/client/components/panels/input/JSONTextArea.vue';

import { useModuleAdmin } from '../../../composables/useModuleAdmin';

export default defineComponent({
  props: {
    module: {
        type: Object,
        required: true
      }
  },
  components: {
    TextField,
    Select,
    SwitchCheck,
    JSONTextArea
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { getClientSettings, updateClientSettings } = useModuleAdmin(props, context);

    const availableLanguageCodes = ['fr','en'];

    const { module } = toRefs(props);

    /**
     * fileTypeConfigs:FileConfiguration[]
     */

    const clientSettings:Ref<any> = ref({});
    const form:Ref<any> = ref({
      fileTypeConfigs : []
    });
    getClientSettings(module.value.module).then((result:any) => {
      clientSettings.value = result.clientSettings;
      updateFormFromClientSettings(result.clientSettings);
    });

    const labels = {
      "title": {
        "fr" : "Paramètres",
        "en" : "Settings"
      },
      "fileTypeConfigs": {
        "fr" : "Configuration des fichiers",
        "en" : "File configuration"
      },
      "fileTypeConfigs_placeholder": {
        "fr" : "Configuration des fichiers",
        "en" : "File configuration"
      },
      "updateButton" : {
        "fr" : "Sauvegarder",
        "en" : "Save"
      }
    }

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-client-settings] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    const updateFormFromClientSettings = (settings:any) => {
      if(settings && settings.fileTypeConfigs != undefined) {
        form.value.fileTypeConfigs = settings.fileTypeConfigs;
      }
    }

    const getSettingsFormForm = () => {
      let settings = {
        fileTypeConfigs: form.value.fileTypeConfigs
      }
      return settings;
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      let settingsInput = getSettingsFormForm();
      laddaSubmit!.start();
      updateClientSettings(module.value.module, settingsInput).then((result:any) => {
        if(result.updated) {
          context.emit('module-updated', result.module);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      form,
      labels,
      onFormSubmit
    }
  }  
})
</script>