<template>
  <validation-provider ref="validator" :name="id" :mode="validationMode" :skipIfEmpty="skipIfEmpty" :rules="computedValidationRules" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        <textarea :id="id" type="text" :placeholder="inputPlaceholder" :class="{...inputCSSClass, 'required':required, ...classes}" v-model="input" :required="required" :disabled="disabled"></textarea>
        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { extend } from "@fwk-node-modules/vee-validate";
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class JSONTextArea extends mixins<GenericInput<string>>(GenericInput) {

  @Prop({
    type: Boolean,
    required: false
  }) readonly disabled!: boolean

  input = this.value ? JSON.stringify(this.value, null, 2) : "";
  
  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  get skipIfEmpty() {
    return true;
  }

  get computedValidationRules() {
    var validationRules:any = {};
    if(this.validationRules) {
      validationRules = {
        ...this.validationRules
      }
    }
    if(this.required) {
      validationRules["required"] = true;
    }
    validationRules["jsonFormat"] = true;
    return validationRules;
  }

  addValidation() {
    var componentInstance = this;
    extend('jsonFormat',{
      params: [],
      validate(content, params):Promise<boolean|string> {

        try {
          var parsedValue = JSON.parse(content);
          return Promise.resolve(true);
        }
        catch(error:any) {
          return Promise.resolve("JSON format is invalid");
        }
      }
    });
  }

  created() {
    this.addValidation();
  }

  mounted() {
    if(this.input != "" && this.$refs.validator) {
      // @ts-ignore
      this.$refs.validator.validate();
    }
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    try {
      var parsedValue = JSON.parse(val);
      this.$emit('update:value', parsedValue);
    }
    catch(error:any) {
      console.log(error);
    }
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    this.input = JSON.stringify(val, null, 2);
    // @ts-ignore
    this.$refs.validator.validate(val);
  }
  
}
</script>