import { Socket, io } from "socket.io-client";
import { messagesTypes } from '@fwk-client/store/types';

export interface SocketVueOptions {
    app:Vue,
    errorsHandler?:Function,
    successesHandler?:Function,
    updatesHandler?:Function,
    completedHandlers?:Function[]
}

var socketInstance:Socket|undefined = undefined;

function getSocketInstance() {
    if(socketInstance == undefined) {
        socketInstance = io({
            transports: [ "websocket" ]
        });
        
        socketInstance.on("connect_error", (err:any) => {
            // the reason of the error, for example "xhr poll error"
            console.log("SOCKET - CONNECT ERROR");
            console.log(err.message);
            // some additional description, for example the status code of the initial HTTP response
            console.log(err.description);
            // some additional context, for example the XMLHttpRequest object
            console.log(err.context);
        });
    }
    return socketInstance;
}



export function emit(path:string, input:any) {
    console.log("SOCKET - EMIT - "+path);
    var socket = getSocketInstance();
    socket.emit(path, input);
}

export function on(path:string, callback:Function) {
    var socket = getSocketInstance();
    socket.on(path, function(input) {
        console.log("SOCKET - ON - "+path);
        callback.call(null, input);
    })
}

function listen(path:string, options:SocketVueOptions) {
    var socket = getSocketInstance();
    if(process.env.CONSOLE == "LOG") {
        console.log("SOCKET - LISTEN - "+path);
    }

    // We remove / from path if it start with it
    if(path.startsWith("/")) {
        path = path.substring(1);
    }
        
    const successHandler = function(response:any) {
        if(process.env.CONSOLE == "LOG") {
            console.log("SOCKET - REQUEST - "+path+" - SUCCESS");
        }
        // We handle API Errors
        handleAPIErrors(response, options);
        // We handle API Successes
        handleAPISuccesses(response, options);

        removeListeners();

        if(options.completedHandlers && options.completedHandlers.length > 0) {
            for(let handler of options.completedHandlers) {
                handler.call(null, response.response);
            }
        }
    };

    const errorHandler = function(response:any) {
        if(process.env.CONSOLE == "LOG") {
            console.log("SOCKET - REQUEST - "+path+" - ERROR");
        }
        // We handle API Errors
        handleAPIErrors(response, options);

        removeListeners();

        if(options.completedHandlers && options.completedHandlers.length > 0) {
            for(let handler of options.completedHandlers) {
                handler.call(null, response.response);
            }
        }
    };

    const updateHandler = function(response:any) {
        if(process.env.CONSOLE == "LOG") {
            console.log("SOCKET - REQUEST - "+path+" - UPDATE");
        }
        // We handle API Errors
        handleAPIErrors(response, options);
        // We handle API Successes
        handleAPISuccesses(response, options);

        if(options.updatesHandler) {
            options.updatesHandler!.call(null, response);
        }
    };

    const removeListeners = function() {
        socket.off(path+":success", successHandler);
        socket.off(path+":error"), errorHandler;
        socket.off(path+":update", updateHandler);
    }

    socket.on(path+":success", successHandler);
    socket.on(path+":error", errorHandler);
    socket.on(path+":update", updateHandler);
}

export function request(path:string, input:any, options:SocketVueOptions, file?:File):Promise<any> {
    var socket = getSocketInstance();
    if(process.env.CONSOLE == "LOG") {
        console.log("SOCKET - REQUEST - "+path);
    }

    // We remove / from path if it start with it
    if(path.startsWith("/")) {
        path = path.substring(1);
    }

    listen(path, options);

    // We emit the request
    if(file) {
        input.file = {
            name: file.name,
            originalname: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified
        }
    }
    
    const withTimeout = (onSuccess:Function, onTimeout:Function, timeout:number) => {
        let called = false;
        
        const timer = setTimeout(() => {
            if (called) return;
            called = true;
            onTimeout();
        }, timeout);
        
        return (...args:any[]) => {
            if (called) return;
            called = true;
            clearTimeout(timer);
            onSuccess.apply(null, args);
        }
    }

    var promise = new Promise((resolve, reject) => {
        socket.emit(path, input, file, withTimeout((data:any) => {
            if(process.env.CONSOLE == "LOG") {
                console.log("SOCKET - REQUEST CALLBACK - "+path);
            }
            resolve(data);
        }, () => {
            reject("SOCKET ACKNOWLEDGE TIMEOUT")
        }, 5000));
    });

    return promise;
}

function handleAPIErrors(response:any, options:SocketVueOptions) {
    if(response.errors && response.errors.length > 0) {
        if(options.errorsHandler) {
            // In case we have a custom errors handler
            options.errorsHandler.call(null, response.errors);
        }
        else {
            // We need to add errors to the list of messages in the store
            options.app.$store.commit('messages/' + messagesTypes.mutations.ADD_MESSAGES, response.errors);
        }

        for(let error of response.errors) {
            gtagExceptionFromError(error.formattedMessage, options);                
        }    
    }
}

function gtagExceptionFromError(description:string, options:SocketVueOptions) {
    // We log it in google analytics if enabled.
    if(options.app.$gtag) {
        if(process.env.CONSOLE == "LOG") {
            console.log("SOCKET - ERROR - GTAG - EXCEPTION: "+description);
        }
        options.app.$gtag.exception({
            description : description,
            fatal:false
        })
    }
}

function handleAPISuccesses(response:any, options:SocketVueOptions) {
    if(response.successes && response.successes.length > 0) {
        if(options.successesHandler) {
            // In case we have a custom successes handler
            options.successesHandler.call(null, response.successes);
        }
        else {
            // We need to add errors to the list of messages in the store
            options.app.$store.commit('messages/' + messagesTypes.mutations.ADD_MESSAGES, response.successes);
        }
    }
}